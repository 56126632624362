<template>
  <el-dialog
    title="Incasari"
    :visible.sync="showPopup"
    id="popup_dialog"
    width="60%"
    class="my-dialog-class"
  >
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >

    <el-row>
      <el-col style="font-weight: bold; color: white; font-size: 17px; text-align: right;">
        Suma ramasa de alocat: <span style="font-weight: bold; color: lightblue;">{{RamasNealocat | format_money}}</span>
      </el-col>

    </el-row>
    <el-table :header-cell-style="{ background: '#393E46' }" :data="ListaFacturi">
      <el-table-column prop="Id" label="#" width="60px"></el-table-column>
      <el-table-column label="Data factura" align="center" >
        <template slot-scope="scope">
            {{scope.row.DataFactura | data}}
        </template>
      </el-table-column>
      <el-table-column label="Data scadenta"  align="center" >
        <template slot-scope="scope">
            <span v-if="scope.row.Scadenta">{{scope.row.Scadenta | data}}</span>
            <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="Total factura (RON)"  align="right" >
        <template slot-scope="scope">
            {{scope.row.TotalFactura | format_money}}
        </template>
      </el-table-column>
      <el-table-column label="Valoare neincasata (RON)" align="right" >
        <template slot-scope="scope">
            {{scope.row.RamasNeincasat | format_money}}
        </template>
      </el-table-column>
      <el-table-column label="Selectata" align="center" >
        <template slot-scope="scope">
          <i style="color:green; font-size: 24px" v-if="scope.row.EsteSelectata == 1" class="el-icon-circle-check"></i>
          <el-button plain round v-if="scope.row.EsteSelectata == 0" :disabled="( RamasNealocat == 0)" size="mini" type="success" @click="alocaSumaIncasare(scope.row)" > selecteaza </el-button>
          <!-- <el-checkbox v-model='scope.row.EsteSelectata' true-label='1' false-label='0' @change="alocaSumaIncasare()"> DA </el-checkbox> -->
        </template>
      </el-table-column>
    </el-table>
    <el-card body-style="padding:10px;" style="margin: 5px 0px 5px 0px">
      <el-row style="margin: -5px 0px -5px 0px">
        <el-col style="text-align: right;">
          <span >Total neincasat: <strong>{{this.TotalValoareNeincasata}}</strong> RON  </span>
        </el-col>
      </el-row>
    </el-card>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" @click="resetIncasari()" > Reseteaza </el-button>
      <el-button @click="showPopup = false"> Renunta </el-button>
      <el-button v-if="this.RamasNealocat == 0" type="primary" @click="save"> Salveaza </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import { format } from "path";

export default {
  name: "Incasari_alocari_dialog",
  extends: BasePage,
  components: {},
  data() {
    return {
      RamasNealocat: 0,
      baseUrl: "",
      showPopup: false,
      mode: "add",
      TotalValoareNeincasata: 0,
      ListaFacturi: [],
      selectedObject: {
        Id: "",
        Tip: "",
        SerieNumar: "",
        IdClient: "",
        NumeClient: "",
        Suma: "",
        Data: "",
        Banca: "",
      },
   
      rules: {
        NumeClient: [{ required: true, message: "Campul este obligatoriu" }],
     
      },
    };
  },
  methods: {
    show_me: async function (linie) {
      this.selectedObject = linie;
      this.showPopup = true;
      var result = await this.post("incasari/get_lista_facturi_neincasate", {IdClient: this.selectedObject.IdClient, IdIncasare: this.selectedObject.Id});
      this.ListaFacturi     = result.ListaFacturi;
      this.incasare         = result.incasare;
      this.RamasNealocat    = this.incasare.Suma;
      this.TotalValoareNeincasata    = result.TotalValoareNeincasata;
    },    
    
    alocaSumaIncasare: async function (linie) {

      if(this.RamasNealocat <= 0) return;

      if(this.RamasNealocat >= Number(linie.RamasNeincasat)){
            this.RamasNealocat -= Number(linie.RamasNeincasatOriginal);
            linie.RamasNeincasat = 0.00;
            linie.EsteSelectata = 1;
      } else {
        linie.RamasNeincasat -= this.RamasNealocat;
        this.RamasNealocat = 0.00;
        linie.EsteSelectata = 1;
      }
    },    
    resetIncasari: async function () {

      this.RamasNealocat = this.incasare.Suma;
      this.ListaFacturi.forEach(element => {
        element.EsteSelectata = 0;
        element.RamasNeincasat = element.RamasNeincasatOriginal;
      });

    },

    async get_info() {
      // var response = await this.post("incasari/get_info_for_dialog");
      // this.Info  = response;
    },

    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          await this.post("incasari/save_alocare", {
            ListaFacturi: this.ListaFacturi,
            IdIncasare: this.selectedObject.Id,
          });
          this.showPopup = false;
          this.$emit("save");
          // location.reload();
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>


#client_cif {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

#client_cif:after {
  content: 'CIF: ' attr(name);
  width: auto;
  max-height: 27px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 10px !important;
  padding: 0 5px;
  margin: 2px 0;
  border: 1px solid transparent;
  border-radius: 7px;
  background-color: #40a0ffa1;
}
.full-width {
  width: 100%;
}
</style>