<template>
  <el-dialog
    title="Incasari"
    :visible.sync="showPopup"
    id="popup_dialog"
    width="90%"
    class="my-dialog-class"
  >
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        <el-col :md="4">
          <el-form-item label="Client" prop="NumeClient" size="mini">
            <div class="full-width">
              <el-select 
              tabindex=0
              ref="focusMe" 
              size="mini" v-model="selectedObject.NumeClient" @change="handleSelectClient" remote
                :remote-method="handleFind" filterable>
                <el-option v-for="item in Info.clienti" :key="item.Id + item.CodFiscal" id="client_cif"
                  :name="item.CodFiscal" :label="item.Nume" :value="item.Id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-col>

        <el-col :md="3">
          <el-form-item label="Tip" prop="Tip" size="mini">
            <el-select
              size="mini"
              class="full-width"
              v-model="selectedObject.Tip"
              filterable
            >
              <el-option label="Chitanta" value="Chitanta" />
              <el-option label="Bon fiscal" value="Bon fiscal" />
              <el-option label="OP" value="OP" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="Numar" prop="SerieNumar" size="mini">
            <el-input
              class="full-width"
              v-model="selectedObject.SerieNumar"
              maxlength="30"
            />
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item label="Suma" prop="Suma" size="mini">
            <el-input-number
              class="full-width"
              v-model="selectedObject.Suma"
              controls-position="right"
              :precision="2"
              :min="0"
              :step="1"
            />
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="Data emitere" prop="Data" size="mini">
            <el-date-picker
              class="full-width"
              v-model="selectedObject.Data"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd.MM.yyyy"
              @change="handleSelectData"
            />
          </el-form-item>
        </el-col>
        <el-col :md="4" v-if="selectedObject.Tip == 'OP'">
          <el-form-item label="Banca" prop="Banca" size="mini">
            <el-input
              class="full-width"
              v-model="selectedObject.Banca"
              maxlength="30"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Renunta </el-button>
      <el-button type="primary" @click="save"> Salveaza </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import moment from "moment";

export default {
  name: "Incasari_dialog",
  extends: BasePage,
  components: {},
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      selectedObject: {
        Id: "",
        Tip: "",
        SerieNumar: "",
        IdClient: "",
        NumeClient: "",
        Suma: "",
        Data: "",
        Banca: "",
      },
      Info: {
        clienti: [],
      },
      rules: {
        NumeClient: [{ required: true, message: "Campul este obligatoriu" }],
        Suma: [{ required: true, message: "Campul este obligatoriu" }],
        Tip: [{ required: true, message: "Campul este obligatoriu" }],
        Data: [{ required: true, message: "Campul este obligatoriu" }],
        SerieNumar: [{ required: true, message: "Campul este obligatoriu" }],
        Banca: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {
    show_me: async function (id) {
      await this.get_info();
      this.selectedObject = {
        Id: "",
        Tip: "",
        SerieNumar: "",
        IdClient: "",
        Suma: 0,
        Data: "",
        Banca: "",
      };
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
        this.$nextTick(()=> {
          this.$refs['focusMe'].focus();
        })
      } else {
        this.mode = "edit";
        var result = await this.post("incasari/get_info_item_dialog", {
          id: id,
        });
        this.selectedObject = result.Item;
      }
    },

    async get_info() {
      var response = await this.post("incasari/get_info_for_dialog");
      // this.Info  = response;
      // this.Info.clientiOld = [...response.clienti];
      // this.Info.clienti = response.clienti;
    },

    async handleSelectData() {

      var result = await this.post("luni_blocate/verifica_data_intrare", { data: this.selectedObject.Data });
      if(result.StatusLuna == 0){
        var data =  moment(this.selectedObject.Data);
        var mesaj =  'Luna ' + this.$options.filters.LunaLitere(data.format('M')) + ' ' +  data.format('Y') + ' este inchisa!';
        this.$notify({
            title: "Atentie",
            message: mesaj,
            dangerouslyUseHTMLString: true,
            type: "warning",
            duration: 5000,
          })

        this.selectedObject.Data = '';
      }
    },

    handleSelectClient(idFz) {
      let item = this.Info.clienti.filter((client) => client.Id == idFz)[0];
      if (parseInt(item.id) < 0) {
        this.selectedObject.NumeClient = "";
      } else {
        var client = item;
        this.selectedObject.IdClient = client.Id;
        this.selectedObject.CifClient = client.CodFiscal;
        this.selectedObject.RegComClient = client.RegCom;
        this.selectedObject.NumeClient = client.Nume;
      }
    },

    async  handleFind( data) {

      if (data !== '') {
        await new Promise(resolve => setTimeout(resolve, 200)); 
        this.Info.clienti = [];
        this.loading = true;

        var result = await this.post("clienti/remoteFindClient", { string: data });
        if(typeof result.ClientiFiltrati !== 'undefined'){
          this.Info.clienti = result.ClientiFiltrati;
        }

      }
    },

    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          await this.post("incasari/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save");
        }
      });
    },
    focusChanged (event) {
      const allElements = document.querySelectorAll('*');
      allElements.forEach((element) => {
        element.classList.remove('selectat');
      });

      const el = event.target
      el.classList.add("selectat");
    }
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
  created() {
    document.addEventListener('focusin', this.focusChanged)
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.focusChanged)
  },
};
</script>

<style lang="less" scoped>

/deep/ .el-checkbox__input.is-focus{
  border: 3px solid yellow !important;
  border-radius: 5px;
}
/deep/ .selectat{
  background-color: yellow !important;
}

#client_cif {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

#client_cif:after {
  content: "CIF: " attr(name);
  width: auto;
  max-height: 27px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 10px !important;
  padding: 0 5px;
  margin: 2px 0;
  border: 1px solid transparent;
  border-radius: 7px;
  background-color: #40a0ffa1;
}
.full-width {
  width: 100%;
}
</style>
