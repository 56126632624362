<template>
  <div style="background-color: black;">
    <b-navbar class="nav-on-top" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand href="#/facturi">
        <img :src="base_url.replace('/api/index.php', '')+ '/api/assets/logo/logo.jpeg'" alt="logo" style="max-height: 30px" /> 
        <!-- cale fisier: mafra/src/assets/sigla -->
      </b-navbar-brand>

      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-collapse is-nav id="nav_collapse" class="menu-bar">
        <b-navbar-nav class="ml-auto overflow-nav full-width">
          <div class="nav-bar-overflow">
            <!-- <b-nav-item href="#/dashboard" class="dashboard">
              <b-icon icon="reception4"></b-icon> Dashboard
            </b-nav-item> -->
            <!-- start aici inseram restul de elemente din meniu -->

            <b-nav-item-dropdown right>
              <template #button-content>
                <b-icon icon="bag-fill"></b-icon> Dictionar
              </template>

              <b-dropdown-item href="#/clienti">
                <b-icon icon="person-fill"></b-icon>
                Clienti
              </b-dropdown-item>
              <b-dropdown-item href="#/clienti-categorii">
                <b-icon icon="list" /> Categorii Clienti
              </b-dropdown-item>
              <b-dropdown-item href="#/furnizori">
                <b-icon icon="file-medical"></b-icon> Furnizori
              </b-dropdown-item>

              <hr />
              <b-dropdown-item href="#/produse">
                <b-icon icon="bag-fill"></b-icon> Produse
              </b-dropdown-item>
              <b-dropdown-item href="#/gestiuni">
                <b-icon icon="wallet-fill"></b-icon> Gestiuni
              </b-dropdown-item>
              <b-dropdown-item href="#/produse-categorii">
                <b-icon icon="list" /> Categorii produse
              </b-dropdown-item>
              <b-dropdown-item href="#/delegati">
                <b-icon icon="file-earmark-text-fill" /> Delegati
              </b-dropdown-item>
              <b-dropdown-item href="#/transport">
                <b-icon icon="truck" /> Transport
              </b-dropdown-item>
              <b-dropdown-item href="#/um">
                <b-icon icon="calculator-fill" /> UM
              </b-dropdown-item>
              <b-dropdown-item href="#/tva">
                <b-icon icon="collection" /> TVA
              </b-dropdown-item>
              <b-dropdown-item href="#/agenti">
                <b-icon icon="person"></b-icon>Agenti
              </b-dropdown-item>
              <b-dropdown-item href="#/ambalaje">
                <b-icon icon="box"></b-icon> Ambalaje
              </b-dropdown-item>
              <b-dropdown-item href="#/serii">
                <b-icon icon="filter-left"></b-icon> Serii facturi
              </b-dropdown-item>
              <b-dropdown-item href="#/luni_blocate">
                <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon> Luni blocate
              </b-dropdown-item>
              <b-dropdown-item href="#/conturi_bancare">
                <b-icon icon="cash-stack" variant="info"></b-icon> Conturi bancare
              </b-dropdown-item>

            </b-nav-item-dropdown>

            <b-nav-item-dropdown right>
              <template #button-content>
                <b-icon icon="grid-3x3-gap"></b-icon> Operatiuni
              </template>
              <b-dropdown-item href="#/avize">
                <b-icon icon="box-arrow-up-right"></b-icon>
                Avize
              </b-dropdown-item> 
              <b-dropdown-item href="#/facturi">
                <b-icon icon="file-ruled"></b-icon>
                Facturi
              </b-dropdown-item>
              <b-dropdown-item href="#/niruri">
                <b-icon icon="box-arrow-in-down-right"></b-icon>
                Niruri
              </b-dropdown-item>
              <b-dropdown-item href="#/transferuri">
                <b-icon icon="arrow-left-right"></b-icon> Transferuri
              </b-dropdown-item>
              <b-dropdown-item href="#/bonuri_consum">
                <b-icon icon="box-arrow-up"></b-icon> Bonuri consum
              </b-dropdown-item>
              <b-dropdown-item href="#/inventar">
                <b-icon icon="file-diff"></b-icon> Inventar
              </b-dropdown-item> 
              <b-dropdown-item href="#/incasari">
                <b-icon icon="piggy-bank" variant="success"></b-icon> Incasari
              </b-dropdown-item>
              <b-dropdown-item href="#/registru_casa">
                <b-icon icon="book" variant="warning"></b-icon> Registru casa
              </b-dropdown-item>
              <!-- <b-dropdown-item href="#/miscari"><b-icon icon="water"></b-icon> Fisa produs</b-dropdown-item> -->
            </b-nav-item-dropdown>
            <b-nav-item href="#/facturi-efactura">
              <b-icon icon="building"></b-icon> Facturi Efactura
            </b-nav-item>


            <!-- end aici inseram restul de elemente din meniu -->

            <!-- <b-navbar-nav class="ml-auto"> -->
            <!-- <b-nav-item-dropdown text="Nomenclatoare" right> -->
            <!-- <b-dropdown-item href="#/Ambalaje">Ambalaje</b-dropdown-item> -->
            <!-- <b-dropdown-item href="#/grupuri-utilizatori">Grupuri utilizatori</b-dropdown-item>
                    <b-dropdown-item href="#/categorii-drepturi">Categorii drepturi</b-dropdown-item>
                    <b-dropdown-item href="#/drepturi">Drepturi</b-dropdown-item> -->
            <!-- </b-nav-item-dropdown> -->

            <b-nav-item-dropdown right>
              <template #button-content>
                <b-icon icon="bar-chart-steps"></b-icon> Rapoarte
              </template>
              <b-dropdown-item href="#/raport_fisa">
                <b-icon icon="clipboard-data"></b-icon> Fisa produs</b-dropdown-item>
                <b-dropdown-item href="#/balanta">
                <b-icon icon="paperclip"></b-icon> Balanta</b-dropdown-item>
              <b-dropdown-item href="#/stoc_marfa">
                <b-icon icon="box-seam"></b-icon>  Stoc marfa</b-dropdown-item>
              <b-dropdown-item href="#/stoc_marfa_clienti">
                <b-icon icon="person-circle"></b-icon> Stoc marfa clienti</b-dropdown-item>
                <!-- <b-dropdown-item href="#/stoc_marfa_niruri">
                <b-icon icon="receipt"></b-icon> Stoc marfa pe niruri</b-dropdown-item> -->
              <b-dropdown-item href="#/top_vanzari">
                <b-icon icon="graph-up"></b-icon> Top vanzari</b-dropdown-item>
                <b-dropdown-item href="#/raport_neincasate">
                <b-icon icon="bug"></b-icon> Raport neincasate</b-dropdown-item>

            </b-nav-item-dropdown>

            <b-nav-item-dropdown right>
              <template #button-content>
                <b-icon icon="gear-fill"></b-icon> Setari
              </template>
              <b-dropdown-item href="#/utilizatori">Utilizatori</b-dropdown-item>
              <b-dropdown-item href="#/grupuri-utilizatori">Grupuri utilizatori</b-dropdown-item>
              <b-dropdown-item href="#/categorii-drepturi">Categorii drepturi</b-dropdown-item>
              <b-dropdown-item href="#/drepturi">Drepturi</b-dropdown-item>
              <b-dropdown-item href="#/setari">Setari generale</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right>
              <template #button-content>
                <b-icon class="user" icon="person-circle"></b-icon> Cont
              </template>
              <b-dropdown-item @click="change_password()">Schimbare parola</b-dropdown-item>
              <b-dropdown-item @click="onLogout()">Deconectare</b-dropdown-item>
            </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <change-password-dialog ref="changePassDlg"></change-password-dialog>
  </div>
</template>

<script>
import settings from "@/backend/LocalSettings";
import ChangePassword_dialog from "@/pages/ChangePassword_dialog";

export default {
  name: "Login",
  data() {
    return {
      user_type: "",
      base_url: settings.BASE_URL,
    };
  },
  components: {
    "change-password-dialog": ChangePassword_dialog,
  },
  methods: {
    post: async function (url, args = {}) {
      this.loadingVisible = true;
      var response = await this.$http.post(url, args);
      this.loadingVisible = false;
      if (settings.verify_response(response)) {
        return response.body;
      } else {
        this.$router.push("/").catch(() => { });
      }
    },

    change_password: function () {
      this.$refs["changePassDlg"].show_me();
    },

    handleSelect: function (item) {
      console.log(item);
    },

    async onLogout() {
      settings.logout();
      this.$router.push("/").catch(() => { });
    },
  },
  mounted() {
    this.user_type = settings.get_user_type();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@gri_inchis: #222831;
@gri_deschis: #393E46;
@portocaliu: #D65A31;
@alb: #EEEEEE;

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  // color: hsl(193, 11%, 34%) !important;
  // opacity: 0.5 !important;
}

.text {
  display: none;
}

.icon:hover+.text {
  display: inline;
}

.navbar-toggler {
  // background-color: hsl(193, 11%, 34%) !important;
}

.el-header {
  padding: 0;
}

.nav-on-top {
  width: 100%;
  z-index: 1000;
}

.navbar {
  width: 100%;
  padding: 10px;
}

.bg-dark {
  background-color: @gri_deschis !important;
  // color: white;
  color: @alb;
}

.dropdown-menu {
  background-color: @gri_deschis;
  color: @alb !important;
}

.navbar-dark .navbar-nav .nav-link {
  // color: hsl(193, 11%, 34%);
  text-transform: uppercase;
  margin: 0 5.5px;
  font-size: 13px;
  transition: 0.3s;
}

#navbar-toggler-icon,
.navbar-toggler {
  // color: hsl(193, 11%, 34%) !important;
}

.navbar,
.navbar-dark {
  box-shadow: 0 0.3px 0.5px 0 rgba(0, 0, 0, 0.2),
    0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.navbar-dark .active .nav-link {
  // background-color: hsla(193, 11%, 34%, 0.126);
  border-radius: 10px;
  // color: hsl(193, 11%, 34%) !important;
}

.dropdown-menu {
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.nav-link {
  width: max-content !important;
}

.dropdown-item {
  // color: hsl(193, 11%, 34%) !important;
  color: @alb;
}

.menu-bar,
.navbar-collapse,
.collapse {
  width: 100% !important;
}

@media only screen and (min-width: 992px) {
  .nav-bar-overflow {
    // overflow-x: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  // .nav-on-top {
  // }
  .overflow-nav {
    overflow-x: initial;
    overflow-y: inherit;
    width: 90% !important;
  }
}

@media only screen and (max-width: 991px) {
  .nav-on-top {
    width: 100% !important;
    overflow: hidden;
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>