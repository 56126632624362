<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <titlu-pagina
                Titlu="Incasari"
                @on_add_clicked="show_dialog()"
                :AdaugaVisible="true"
              />
            </div>
          </template>
          <div class="filtre">
            <el-form
              @submit.prevent.native="refresh_info()"
              label-position="top"
              size="small"
            >
              <el-row :gutter="20">
                <el-col>
                  <el-form-item label="Client">
                    <el-input v-model="Filters.Nume"/>
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label='Interval' >
                    <el-date-picker
                    
                        v-model="Filters.Data"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="Data inceput"
                        end-placeholder="Data sfarsit"
                        :picker-options="datePickerOptions"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="Tip">
                    <el-select v-model="Filters.Tip" class="full-width">
                      <el-option label="Toate" value="-1"/>
                      <el-option label="Chitanta" value="Chitanta"/>
                      <el-option label="Bon fiscal" value="Bon fiscal" />
                      <el-option label="OP" value="OP" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col style="width: 100%">
                  <div class="footer-form">
                    <el-button
                    size="small"
                      type="primary"
                      @click="refresh_info()"
                    >
                      Aplica
                    </el-button>
                    <el-button
                    size="small"
                      type="danger"
                      @click="reset"
                    >
                      Reseteaza
                    </el-button>
                    <el-button size="small" type="info" native-type="submit" @click="print()">Printeaza</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <el-table :header-cell-style="{ background: '#393E46' }"  :data="Results" class="tabelRandMic">
      <el-table-column prop="Id" label="#" width="60px"></el-table-column>
      <el-table-column prop="NumeClient" label="Client"></el-table-column>
      <el-table-column label="Data" width="100px">
        <template slot-scope="scope">
          {{scope.row.Data | Data}}
        </template>
      </el-table-column>
      <el-table-column prop="Tip" label="Tip" width="77px" />
      <el-table-column prop="SerieNumar" label="Numar" width="130px" style="max-width: 50px;" />
      <el-table-column label="Suma" align="right" >
        <template slot-scope="scope">
          {{scope.row.Suma | format_money}}
        </template>
      </el-table-column>
      <el-table-column label="Alocata" style="text-align: center;" >
        <template slot-scope="scope">
          <el-popover
            width="700"
            :title="'Alocari pe incasarea de ' + scope.row.Suma + ' Ron ' + scope.row.Tip + ' ' + scope.row.SerieNumar + ' din ' + (scope.row.Data )" 
            trigger="click">
            <el-table :header-cell-style="{ background: '#393E46' }"  :data="scope.row.incasari">
              <el-table-column  prop="Id" label="#" width="50px"></el-table-column>
              <el-table-column  label="Serie/Numar">
                <template slot-scope="scope">
                  {{scope.row.Serie}} {{scope.row.Numar}}
                  <!-- {{ scope.row.Serie + scope.row.Numar}} -->
                </template>
              </el-table-column>
              <el-table-column  label="Data factura">
                <template slot-scope="scope">
                  {{scope.row.DataFactura | data}}
                </template>
              </el-table-column>
              <el-table-column align="right" label="Alocat">
                <template slot-scope="scope">
                  {{scope.row.SumaAlocata | format_money}}
                </template>
              </el-table-column>
              <el-table-column align="right"  label="Neincasat">
                <template slot-scope="scope">
                  {{scope.row.RamasNeincasat | format_money}}
                </template>
              </el-table-column>
            </el-table>
            <!-- <el-button slot="reference">Click to activate</el-button> -->
            <el-button v-if="scope.row.Alocata"
            slot="reference"
              plain
              icon="el-icon-circle-check"
              size="mini"
              circle
              style="color:green;"
              class="butonReg" />
          </el-popover>
          <span v-if="!scope.row.Alocata">Nu</span>
        </template>
      </el-table-column>
      <el-table-column
        style="text-align: right;"
        fixed="right"
        label="Actiuni"
        width="150px"
      >
        <template slot-scope="scope">
          <el-tooltip v-if="!scope.row.Alocata"  content="Aloca incasarea">
            <el-button
              type="success"
              icon="el-icon-connection"
              size="mini"
              circle
              @click="show_alocari_dialog(scope.row)"
              class="butonReg" />
          </el-tooltip>           
          <el-tooltip v-if="scope.row.Alocata"  content="Sterge alocarea">
            <el-button
              type="danger"
              icon="el-icon-link"
              size="mini"
              circle
              @click="sterge_alocarea(scope.row)"
              class="butonReg" />
          </el-tooltip>  
          <el-tooltip v-if="!scope.row.Alocata" content="Modificare">
            <el-button
            :disabled="scope.row.LunaBlocata"
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              @click="show_dialog(scope.row.Id)"
              class="butonReg" />
          </el-tooltip>
          <el-tooltip content="Sterge incasarea">
            <el-button
            :disabled="scope.row.LunaBlocata"
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              @click="sterge_incasarea(scope.row)"
              class="butonReg" />
          </el-tooltip>  
          <!-- <el-tooltip content="Dezactiveaza">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              :disabled="Boolean(Number(scope.row.Dezactivat))"
              @click="delete_item(scope.row)"
            />
          </el-tooltip> -->
          <!-- <el-tooltip content="Descarca PDF">
            <el-button type="success" size="mini" icon="el-icon-download" circle @click="downloadPdf(scope.row.SerieNumar)"  class="butonReg" />
          </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="refresh_info"
      @current-change="refresh_info"
      :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page"
      :total="PaginationInfo.RowCount"
      layout="pager"
    />
    <Incasari-dialog ref="dlg" @save="refresh_info()" />
    <Incasari-alocari-dialog ref="dlg_alocari" @save="refresh_info()" />
  </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Incasari_dialog from "@/pages/incasari/Incasari_dialog.vue";
import Incasari_alocari_dialog from "@/pages/incasari/Incasari_alocari_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";

export default {
  name: "incasari",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Incasari-dialog": Incasari_dialog,
    "Incasari-alocari-dialog": Incasari_alocari_dialog,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      datePickerOptions: settings.datePickerOptions,
      Results: [],
      base_url: "",
      Info: {
        // clienti: [],
      },
      Filters: {
        Tip: "-1",
        Data: [],        
        Nume: "",
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async get_info() {
      var response = await this.post("incasari/get_info");
      // this.Info.clienti = response.clienti;
      // if (this.Filters.IdClient.length > 0)
      //   this.Filters.IdClient = this.Info.clienti[0].Id;
      this.refresh_info();
    },

    async refresh_info() {
      // console.log(Date.now())
      var response = await this.post("incasari/index_incasari", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item("Operatiuni");
      
    },
    reset() {
      this.Filters = {
        Tip: "-1",
        Data: [],
        Nume: "",
      };
      this.refresh_info();
    },
    
    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    }, 
    show_alocari_dialog(linie) {
      this.$refs.dlg_alocari.show_me(linie);
    },  

    async sterge_alocarea(linie) {
      this.$confirm(`Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {
          let response = await this.post("incasari/sterge_alocarea", { IdIncasare: linie.Id });
          this.refresh_info();

          if(response.Success){
            this.$swal({
              toast: true,
              icon: 'success',
              title: "Alocarea a fost stearsa",
              showConfirmButton: false,
              timer: 4500,
              showCloseButton: true,
              timerProgressBar: true,
              width: 400,
            });
          }
        })
        .catch(() => {
        });
    },  
    async sterge_incasarea(linie) {
      this.$confirm(`Se va sterge incasarea. Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {
          let response = await this.post("incasari/sterge_incasarea", { IdIncasare: linie.Id });
          this.refresh_info();

          if(response.Success){
            this.$swal({
              toast: true,
              icon: 'success',
              title: "Incasarea a fost stearsa",
              showConfirmButton: false,
              timer: 4500,
              showCloseButton: true,
              timerProgressBar: true,
              width: 400,
            });
          } else {
            this.$swal({
              toast: true,
              icon: 'error', // nu
              title: response.Eroare,
              showConfirmButton: false,
              timer: 4500,
              showCloseButton: true,
              timerProgressBar: true,
              width: 400,
            });
          }
        })
        .catch(() => {
        });
    }, 
    async downloadPdf(numar){
      let res = await this.post("incasari/index_incasari?print=1", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      // const toDownloadDocument = new jsPDF()
      
      const blankWindow = window.open('', '_blank');

      html2pdf(res.html, {
				margin: 0,
  			filename: 'Incasari_' + numar,
        html2canvas: { scale: 4 },
        jsPDF: {orientation: 'landscape'},
			}).then(() => {
        blankWindow.close();
      });
      // toDownloadDocument.html(res.html, {
      //   callback: () => {
      //     toDownloadDocument.save('Incasari_' + numar)
      //     blankWindow.close();
      //   },
      //   x: 15,
      //   y: 15,
      //   width: 180,
      //   windowWidth: 1200,
      // })
    },
    async print() {
      let res = await this.post("incasari/index_incasari?print=1", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      console.log(res)
      // let res = await this.post('Incasari/print', { Filters: this.Filters, Produse: this.Result.balanta });
      let doc = window.open("", "_blank");
      doc.document.write(res.html);
    },
    
  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .filtre {
    display: flex;
    grid-template-columns: none;
  }
  .el-table tr {
    word-break: break-word;
    // font-size: 12px !important;
  }
  .footer-form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);
  .el-col {
    width: 33%;
  }

  // grid-auto-rows: 100px;
  .el-input-number {
    width: 100% !important;
  }
}
</style>
