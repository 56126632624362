<template>
<div class='titlu' style="background-color: #393E46;">
    <el-row>
        <el-col class="display-titlu">

            <h1 class="m-0"> {{Titlu}} </h1>
            <el-button @click.stop.prevent v-if="AdaugaVisible" class="btn-adauga" type='primary' style="margin-left: 20px" @click="on_add_clicked" icon='el-icon-plus'> {{AdaugaText}} </el-button>

        </el-col>
    </el-row>
</div>
</template>

<script>

import settings from '@/backend/LocalSettings';

export default {
    name: 'TitluPagina',
    props:{
        Titlu: "",
        AdaugaText: "",
        AdaugaVisible: true
    },
    data () {
        return {
        }
    },
    methods:
        {
            on_add_clicked: function(){
                this.$emit('on_add_clicked');
            },
        }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .titlu {
        margin: 0;
        padding: 0;
    }

</style>